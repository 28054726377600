/**
 *
 * @author MaximAL
 * @since 2019-10-24
 * @date 2019-10-24
 * @time 14:33
 * @copyright © MaximAL, Sijeko 2019
 */

const BUTTON_ATTRIBUTE = 'data-switch-dark-mode';
const DARK_MODE_VALUE = 'dark';
const LIGHT_MODE_VALUE = '';
const DARK_MODE_BODY_CLASS = 'dark-mode';

/**
 * Инициализировать кнопку для переключения тёмной темы
 * @param {Node} buttonElement
 * @param {Document} document
 */
export function initDarkModeSwitch(buttonElement, document) {
	const body = document.body;
	buttonElement.addEventListener('click', event => {
		const target = event.currentTarget;
		const current = target.getAttribute(BUTTON_ATTRIBUTE);
		if (current === DARK_MODE_VALUE) {
			target.setAttribute(BUTTON_ATTRIBUTE, LIGHT_MODE_VALUE);
			body.classList.remove(DARK_MODE_BODY_CLASS);
		} else {
			target.setAttribute(BUTTON_ATTRIBUTE, DARK_MODE_VALUE);
			body.classList.add(DARK_MODE_BODY_CLASS);
		}
	});
}
