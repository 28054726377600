/**
 *
 * @author MaximAL
 * @since 2019-10-22
 * @date 2019-10-22
 * @time 14:50
 * @copyright © MaximAL, Sijeko 2019
 */

import Chat from './chat';
import { initDarkModeSwitch } from './switch-dark-mode';

const MAX_MESSAGES = 100;
const TEXT_EFFECT_CLASSES = [
	'glitch1',
	'glitch2',
];
let textEffectIndex = 0;

const THROTTLE_MESSAGES_SEC = 5;

let lastMessage = {};

export function run(window, document) {
	console.info('Hello.');

	const chatNode = document.querySelector('[data-chat-id]');

	const chat = new Chat();
	chat.init(chatNode.getAttribute('data-chat-id'));

	chat.onConnect = () => {
		// Присоединились
	};
	chat.onDisconnect = () => {
		// Отсоединились
	};
	chat.onMessageIn = data => {
		// Пришло сообщение
		processMessage(data, chatNode, document);
	};
	chat.onMessageEdit = data => {
		// Сообщение отредактировано
		console.log(data);
	};

	initDarkModeSwitch(document.querySelector('[data-switch-dark-mode]'), document);
}

/**
 * Обработать сообщение
 * @param {Object} data Сообщение
 * @param {String} data.type Тип сообщения
 * @param {String} data.text Текст сообщения
 * @param {String} data.name Имя автора
 * @param {String} data.time Дата сообщения
 * @param {Boolean} data.emojiOnly Состоит ли сообщение только из Эмодзи
 * @param {String} [data.username] @логин автора
 * @param {String} data.userId ИД автора
 * @param {Node} chatNode Узел дома, к которому прикрепляются сообщения
 * @param {Document} document Документ
 */
function processMessage(data, chatNode, document) {
	//console.log(data);
	if (!data.text) {
		return;
	}
	const text = data.text.trim();
	const name = data.name.trim();

	if (text.length < 1) {
		return;
	}

	if (tooOften(data)) {
		return;
	}

	// Отобразить текст wrap-glitch
	const messageNode = document.createElement('article');
	messageNode.classList.add('message', 'j-message');
	if (data.emojiOnly) {
		messageNode.classList.add('emoji-only');
	}

	// Добавляем заголовок с глитч-эффектом
	const wrapGlitch = document.createElement('div');
	wrapGlitch.className = 'wrap-glitch';

	// Заголовок
	const header = document.createElement('h2');
	header.className = TEXT_EFFECT_CLASSES[textEffectIndex];
	header.textContent = name;
	header.dataset.text = name;
	wrapGlitch.appendChild(header);
	messageNode.appendChild(wrapGlitch);

	// Время сообщения
	const time = document.createElement('time');
	time.classList.add('message-time');
	time.setAttribute('datetime', data.time);
	time.innerText = data.time;
	messageNode.appendChild(time);

	// Переключаемся на следующий класс с эффектом
	textEffectIndex = ++textEffectIndex % TEXT_EFFECT_CLASSES.length;

	// добавляем текст сообщения с эффектом появления
	// const masChar = text.split('');

	// masChar.forEach((char, i) => {
	// 	setTimeout(() => {
	// 		let newSpan = document.createElement('span');
	// 		newSpan.className = 'animate';
	// 		newSpan.textContent = char;
	//
	// 		messageNode.appendChild(newSpan);
	// 	}, (i + 1) * 50);
	// });

	const newSpan = document.createElement('span');
	newSpan.className = 'glitch3';
	newSpan.textContent = text;

	messageNode.appendChild(newSpan);
	chatNode.appendChild(messageNode);

	// Удаляем старые сообщения
	const messageNodes = document.querySelectorAll('.j-message');
	if (messageNodes.length > MAX_MESSAGES) {
		const toDelete = messageNodes.length - MAX_MESSAGES;
		for (let index in messageNodes) {
			if (!messageNodes.hasOwnProperty(index)) {
				continue;
			}
			if (index >= toDelete) {
				break;
			}
			console.log(index);
			const element = messageNodes[index];
			element.remove();
		}
	}
}

/**
 * Не отсылает ли пользователь сообщения слишком часто?
 * @param {Object} message Сообщение
 * @param {Number} message.userId ИД пользователя
 * @param {String} message.time Время сообщения
 * @returns {boolean} Возвращает `true`, если сообщения идут слишком часто, и `false` в противном случае
 */
function tooOften(message) {
	const userId = message.userId;
	if (!userId) {
		return true;
	}

	const messageTime = new Date(message.time).getTime();
	if (lastMessage[userId]) {
		if (messageTime - lastMessage[userId] < THROTTLE_MESSAGES_SEC * 1000) {
			return true;
		}
	}
	lastMessage[userId] = messageTime;

	return false;
}
