/**
 *
 * @author MaximAL
 * @since 2019-10-22
 * @date 2019-10-22
 * @time 14:49
 * @copyright © MaximAL, Sijeko 2019
 */

import { run } from './app';

document.addEventListener('DOMContentLoaded', () => {
	run(window, document);
});
