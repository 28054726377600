/**
 *
 * @author MaximAL
 * @since 2019-01-12
 * @date 2019-01-12
 * @time 13:06
 * @copyright © MaximAL, Sijeko 2019
 */

/**
 * Чат на веб-сокетах.
 *
 * При потере подключения производятся попытки переподключения через разные
 * промежутки времени.
 *
 * @constructor
 */
export default function Chat() {
	let self = this;

	const WEBSOCKET_URL = 'wss://wall.tgfind.me/ws';

	self.id = null;
	self.clientId = null;
	self.clientProfile = {name: null, phone: null, email: null, address: null, username: null};

	self.connected = false;
	self.lastConnected = null;
	self.lastConnectTry = null;
	self.connection = null;
	self.failedCount = 0;
	self.connectIntervals = [0, 1, 1, 2, 4, 8, 16, 32, 64, 128];

	self.pingTimerId = null;
	const PING_TIMEOUT = 60;

	const TYPE_INIT = 'init';
	const TYPE_TEST = 'test';
	const TYPE_MESSAGE = 'message';
	const TYPE_RESPONSE_EDIT = 'response.edit';
	const TYPE_RATE = 'rate';
	const TYPE_RATE_SET = 'rate.set';


	self.init = function (id) {
		id = id || null;
		if (!id) {
			throw new Error('Chat needs an ID on init');
		}
		self.id = id;
		self.clientId = localStorage.getItem('telesite.clientId');
		this.connect();
	};

	self.connect = function () {
		// Вебсокет подключён
		//console.log('Chat.connect()');
		self.connection = new WebSocket(WEBSOCKET_URL + '?id=' + self.id +
			(self.clientId ? ('&client=' + self.clientId) : ''));
		self.connection.onopen = self.onOpen;
		self.connection.onmessage = self.onMessage;
		self.connection.onclose = self.onClose;
		self.connection.onerror = self.onError;
	};

	self.onOpen = function (event) {
		// Вебсокет открыт
		//console.log('onOpen()');

		self.failedCount = 0;
		self.connected = true;
		self.onConnect && self.onConnect();

		// Поздоровались
		self.sendData({type: 'handshake', clientId: self.clientId});
		// Периодически пингуем
		self.pingTimerId = setInterval(function () {
			console.info(new Date().toISOString() + ' ping');
			self.sendData({type: 'ping'});
		}, PING_TIMEOUT * 1000);
	};
	self.onClose = function (event) {
		// Вебсокет закрыт
		//console.log('onClose()');
		self.connected = false;
		self.onDisconnect && self.onDisconnect();
		clearInterval(self.pingTimerId);
		self.pingTimerId = null;
		self.reconnect();
	};
	self.onError = function (event) {
		//this.reconnect();
		//console.error(event);
	};

	self.onMessage = function (event) {
		// Пришло сообщение из веб-сокета
		console.log('onMessage(): ' + event.data);
		let data = JSON.parse(event.data);
		switch (data.type) {
			case TYPE_INIT:
				self.onMessageInit(data);
				break;
			case TYPE_TEST:
				self.onMessageTest(data);
				break;
			case TYPE_MESSAGE:
				self.onMessageIn && self.onMessageIn(data);
				break;
			case TYPE_RESPONSE_EDIT:
				self.onMessageEdit && self.onMessageEdit(data);
				break;
			default:
				console.warn('Unknown data type: ' + data.type);
				break;
		}
	};

	self.reconnect = function () {
		// Переподключение
		if (self.connected) {
			return;
		}
		// Пытаемся подключиться через разные промежутки времени
		let timeout = self.connectIntervals[self.failedCount % self.connectIntervals.length];
		console.warn('reconnecting... try #' + self.failedCount + ' with timeout of ' + timeout + ' s');
		setTimeout(self.connect, timeout * 1000);
		self.failedCount++;
	};

	/**
	 * Отослать данные в веб-сокет
	 * @param {Object} data Данные произвольного формата
	 */
	self.sendData = function (data) {
		//console.log('sendData(): ' + JSON.stringify(data));
		if (self.connected) {
			self.connection.send(JSON.stringify(data));
		}
	};

	self.onMessageInit = function (data) {
		if (!self.clientId) {
			self.clientId = data.clientId;
			localStorage.setItem('telesite.clientId', self.clientId);
		}
		self.onProfileReceive && self.onProfileReceive(data);
	};

	self.onMessageTest = function (data) {
		console.info('Сообщение всем: ' + data.message);
	};

	/**
	 * Послать текст в веб-сокет
	 * @param {String} text Текст для отсылки
	 */
	self.sendText = function (text) {
		self.sendData({type: 'text', text: text});
	};

	/**
	 * Поставить оценку сеансу
	 * @param {Number} rate Оценка, целое число от 1 до 5; 0 — удалить
	 *     оценку
	 */
	self.sendRate = function (rate) {
		self.sendData({type: TYPE_RATE, rate: parseInt(rate)});
	};

	/**
	 * Событие при подключении
	 * @type {Function|null}
	 */
	self.onConnect = null;
	/**
	 * Событие при отключении
	 * @type {Function|null}
	 */
	self.onDisconnect = null;
	/**
	 * Событие при получении профиля (он передаётся в параметре)
	 * @type {Function|null}
	 */
	self.onProfileReceive = null;
	/**
	 * Событие при получении входящего сообщения от оператора
	 * (объект с данными собщения передаётся в параметре)
	 * @type {Function|null}
	 */
	self.onMessageIn = null;
	/**
	 * Событие при получении исправления сообщения от оператора
	 * (объект с данными собщения передаётся в параметре)
	 * @type {Function|null}
	 */
	self.onMessageEdit = null;
	//this.init();
}
